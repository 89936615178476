.skills-inner-wrap {
  background: no-repeat url(/src/images/peachwavy.png);
  background-size: 100% 100%;
  padding: 1%;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.skills-wrap {
  height: 70vh;
}
.skills-container {
  margin: 3%;
  .skills-title {
    font-size: 40px;
    text-align: center;
    padding-bottom: 2%;
    color: #734a44;
  }
}
.resume-btn {
  width: fit-content;
  margin: auto;
}
.icon-wrapper {
  margin-top: 2%;
  display: flex;
  justify-content: space-around;
  .icon-card {
    text-align: center;
    margin-bottom: 2%;
    svg {
      display: flex;
      margin: auto;
      max-width: 55px;
      max-height: 70px;
    }
    img {
      display: flex;
      margin: auto;
      width: 65px;
      height: 65px;
    }
    span {
      color: #97594c;
      font-style: italic;
    }
  }
}

@media only screen and (max-width: 765px) {
  .skills-wrap {
    height: 60vh;
  }
  .skills-container {
    margin: 2% 2% 5% 2%;
    .skills-title {
      font-size: 35px;
      text-align: center;
      padding-bottom: 5%;
      color: #734a44;
    }
    .icon-wrapper {
      margin-top: 2%;
      display: unset;
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      .icon-card {
        text-align: center;
        margin-bottom: 2%;
        svg {
          margin: auto;
          max-width: 55px;
          max-height: 70px;
        }
        img {
          display: flex;
          margin: auto;
          width: 65px;
          height: 65px;
        }
        span {
          color: #97594c;
        }
      }
    }
    .resume-btn {
      margin: 4% auto 8% auto;
      width: fit-content;
    }
  }
}
