.project-wrap {
  padding: 2%;
  padding-top: 7%;
  margin-bottom: 7%;
}

.project-wrap .title {
  margin: auto;
  font-size: 40px;
  text-align: center;
  color: #5a3d39;
}

.project-wrap .project-underline {
  max-width: 250px;
  margin: auto;
}

.project-wrap .project-box {
  max-width: 1400px;
  margin: auto;
}

.project-wrap .project-container {
  margin: 5% 0 2% 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.project-wrap .project-container .img-box {
  width: 70%;
  height: auto;
  padding: 0.4%;
  z-index: 1;
}

.project-wrap .project-container .img-box img {
  width: 100%;
  height: 100%;
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
}

.project-wrap .project-container .img-box img:hover {
  -webkit-filter: none;
          filter: none;
}

.project-wrap .project-container .proj-content {
  background-color: #c4c4c442;
  border-radius: 5%;
  margin: auto;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.project-wrap .project-container .proj-content .proj-title {
  text-align: center;
  font-size: 22px;
}

.project-wrap .project-container .proj-content .proj-title a {
  text-decoration: none;
  color: black;
}

.project-wrap .project-container .proj-content .proj-title a:hover {
  color: #5a3d39;
}

.project-wrap .project-container .proj-content .proj-type {
  color: #5a3d39;
  text-align: center;
  font-size: 17px;
}

.project-wrap .project-container .proj-content .icon-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.project-wrap .project-container .proj-content .icon-wrap span {
  margin: 2% 2% 0 0;
  min-width: 22px;
  text-align: center;
  border-radius: 15%;
  background-color: #f5b77a;
  max-height: 20px;
}

.project-wrap .project-container .proj-content .icon-wrap span img {
  max-height: 20px;
}

.project-wrap .project-container:nth-child(even) {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
  clear: both;
}

.project-wrap .project-container:nth-child(even) .img-box {
  margin-left: -50px;
}

.project-wrap .project-container:nth-child(even) .proj-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.project-wrap .project-container:nth-child(even) .proj-links span {
  margin: 2% 0% 2% 3%;
}

.project-wrap .project-container:nth-child(even) .proj-links span .fa-github {
  color: black;
  font-size: 20px;
}

.project-wrap .project-container:nth-child(even) .proj-links span .fa-github:hover {
  color: #5a3d39;
}

.project-wrap .project-container:nth-child(even) .proj-links span .fa-globe {
  color: black;
  font-size: 20px;
}

.project-wrap .project-container:nth-child(even) .proj-links span .fa-globe:hover {
  color: #5a3d39;
}

.project-wrap .project-container:nth-child(even) .proj-description {
  font-size: 14px;
  margin-right: 50px;
  padding: 4% 0 0 2%;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
}

.project-wrap .project-container:nth-child(odd) {
  clear: both;
}

.project-wrap .project-container:nth-child(odd) .img-box {
  margin-right: -50px;
}

.project-wrap .project-container:nth-child(odd) .proj-links {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.project-wrap .project-container:nth-child(odd) .proj-links span {
  margin: 2% 3% 2% 0%;
}

.project-wrap .project-container:nth-child(odd) .proj-links span .fa-github {
  color: black;
  font-size: 20px;
}

.project-wrap .project-container:nth-child(odd) .proj-links span .fa-github:hover {
  color: #5a3d39;
}

.project-wrap .project-container:nth-child(odd) .proj-links span .fa-globe {
  color: black;
  font-size: 20px;
}

.project-wrap .project-container:nth-child(odd) .proj-links span .fa-globe:hover {
  color: #5a3d39;
}

.project-wrap .project-container:nth-child(odd) .proj-description {
  font-size: 14px;
  margin-left: 50px;
  padding: 4% 0 0 2%;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
}

@media only screen and (max-width: 765px) {
  .project-wrap {
    padding-top: 10%;
  }
  .project-wrap .title {
    font-size: 35px;
  }
  .project-container {
    position: relative;
  }
  .project-container .img-box {
    display: none;
  }
  .project-container .proj-content {
    width: 100%;
    margin: 0;
    padding: 0;
  }
  .project-container .proj-description {
    margin: 0px !important;
  }
  .project-container .proj-links {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }
  .project-container .proj-links span {
    margin: 2% 3% 2% 0% !important;
  }
}
