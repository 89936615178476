.project-wrap {
  padding: 2%;
  padding-top: 7%;
  margin-bottom: 7%;
  .title {
    margin: auto;
    font-size: 40px;
    text-align: center;
    color: #5a3d39;
  }
  .project-underline {
    max-width: 250px;
    margin: auto;
  }
  .project-box {
    max-width: 1400px;
    margin: auto;
  }
  .project-container {
    margin: 5% 0 2% 0;
    display: flex;
    .img-box {
      width: 70%;
      height: auto;
      padding: 0.4%;
      z-index: 1;
      img {
        width: 100%;
        height: 100%;
        filter: grayscale(100%);
        &:hover {
          filter: none;
        }
      }
    }
    .proj-content {
      background-color: #c4c4c442;
      border-radius: 5%;
      margin: auto;
      width: 100%;
      height: fit-content;
      .proj-title {
        text-align: center;
        font-size: 22px;
        a {
          text-decoration: none;
          color: black;
          &:hover {
            color: #5a3d39;
          }
        }
      }
      .proj-type {
        color: #5a3d39;
        text-align: center;
        font-size: 17px;
      }
      .icon-wrap {
        display: flex;
        justify-content: center;
        span {
          margin: 2% 2% 0 0;
          min-width: 22px;
          text-align: center;
          border-radius: 15%;
          background-color: #f5b77a;
          max-height: 20px;
          img {
            max-height: 20px;
          }
        }
      }
    }
  }
  .project-container:nth-child(even) {
    flex-direction: row-reverse;
    clear: both;
    .img-box {
      margin-left: -50px;
    }
    .proj-links {
      display: flex;
      width: 100%;
      span {
        margin: 2% 0% 2% 3%;
        .fa-github {
          color: black;
          font-size: 20px;
          &:hover {
            color: #5a3d39;
          }
        }
        .fa-globe {
          color: black;
          font-size: 20px;
          &:hover {
            color: #5a3d39;
          }
        }
      }
    }
    .proj-description {
      font-size: 14px;
      margin-right: 50px;
      padding: 4% 0 0 2%;
      overflow-wrap: break-word;
      word-wrap: break-word;
      hyphens: auto;
    }
  }
  .project-container:nth-child(odd) {
    clear: both;
    .img-box {
      margin-right: -50px;
    }
    .proj-links {
      justify-content: flex-end;
      display: flex;
      width: 100%;
      span {
        margin: 2% 3% 2% 0%;
        .fa-github {
          color: black;
          font-size: 20px;
          &:hover {
            color: #5a3d39;
          }
        }
        .fa-globe {
          color: black;
          font-size: 20px;
          &:hover {
            color: #5a3d39;
          }
        }
      }
    }
    .proj-description {
      font-size: 14px;
      margin-left: 50px;
      padding: 4% 0 0 2%;
      overflow-wrap: break-word;
      word-wrap: break-word;
      hyphens: auto;
    }
  }
}

@media only screen and (max-width: 765px) {
  .project-wrap {
    padding-top: 10%;
    .title {
      font-size: 35px;
    }
  }
  .project-container {
    position: relative;

    .img-box {
      display: none;
    }
    .proj-content {
      width: 100%;
      margin: 0;
      padding: 0;
    }
    .proj-description {
      margin: 0px !important;
    }
    .proj-links {
      justify-content: flex-end;
      span {
        margin: 2% 3% 2% 0% !important;
      }
    }
  }
}
