@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;500&display=swap");
.app {
  font-family: "Nunito", sans-serif;
  min-height: 100vh;
  max-width: 100vw;
}
@media only screen and (max-width: 900px) {
  .app-test {
    filter: blur(5px);
    transition: 300ms;
  }
}
