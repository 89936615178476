.nav-menu {
  position: fixed;
  width: 100%;
  -webkit-transition: 0ms;
  transition: 0ms;
  background: no-repeat url(../images/wavyheader.png);
  background-size: 100% 100%;
}

.nav-menu ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: right;
      -ms-flex-pack: right;
          justify-content: right;
  height: 3.2vw;
}

.nav-menu ul li {
  display: inline-block;
  font-size: 17px;
  list-style: none;
  margin: 0 5% 0 0;
  margin-top: auto;
}

.nav-menu .a-link {
  display: inline-block;
  text-decoration: none;
  position: relative;
  color: black;
}

.nav-menu .a-link::after {
  content: "";
  position: absolute;
  width: 100%;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #5a3d39;
  -webkit-transform-origin: bottom right;
          transform-origin: bottom right;
  -webkit-transition: -webkit-transform 0.25s ease-out;
  transition: -webkit-transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
  transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
}

.nav-menu .a-link:hover::after {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left;
}

.nav-menu .active::after {
  content: "";
  width: 100%;
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  height: 2px;
  background-color: #5a3d39;
}

.mobile-nav-menu {
  position: fixed;
  width: 100%;
  -webkit-transition: 0ms;
  transition: 0ms;
  background: no-repeat url(../images/wavyheader.png);
  background-size: 100% 100%;
}

.mobile-nav-menu ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: right;
      -ms-flex-pack: right;
          justify-content: right;
  height: 3.2vw;
}

.mobile-nav-menu ul li {
  display: inline-block;
  font-size: 17px;
  list-style: none;
  margin: 0 5% 0 0;
  margin-top: auto;
}

.mobile-nav-menu .a-link {
  display: inline-block;
  text-decoration: none;
  position: relative;
  color: black;
}

.mobile-nav-menu .a-link::after {
  content: "";
  position: absolute;
  width: 100%;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #5a3d39;
  -webkit-transform-origin: bottom right;
          transform-origin: bottom right;
  -webkit-transition: -webkit-transform 0.25s ease-out;
  transition: -webkit-transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
  transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
}

.mobile-nav-menu .a-link:hover::after {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left;
}

.mobile-nav-menu .active::after {
  content: "";
  width: 100%;
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  height: 2px;
  background-color: #5a3d39;
}

.mobile-btn {
  z-index: 1111;
  display: none;
  float: right;
  font-size: 22px;
  background-color: transparent;
  border: transparent;
  margin: 10px 10px 0 0;
  cursor: pointer;
}

.nav-wrap {
  background: no-repeat url(../images/wavyheader.png);
  background-size: 100% 100%;
  margin: 0;
  position: fixed;
  width: 100%;
  z-index: 9;
  min-height: 65px;
}

@media only screen and (max-width: 900px) {
  .nav-menu {
    -webkit-transition: 450ms;
    transition: 450ms;
    right: 0;
    width: 250px;
    background-color: #d47b59;
    height: 100vh;
    z-index: -1;
  }
  .nav-menu ul {
    margin-top: 100px;
    height: unset;
    padding: 0px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .nav-menu ul li {
    font-size: 17px;
    text-align: center;
    display: inline-block;
    list-style: none;
    margin: auto;
    padding: 0px;
    width: 100%;
  }
  .nav-menu ul li:hover {
    background-color: #f5b77a;
  }
  .nav-menu .a-link {
    display: inline-block;
    text-decoration: none;
    position: relative;
    color: black;
    padding: 5% 0 5% 0;
    width: 100%;
  }
  .nav-menu .a-link::after {
    position: unset;
    -webkit-transition: none;
    transition: none;
  }
  .nav-menu .a-link:hover::after {
    -webkit-transform: none;
            transform: none;
  }
  .nav-menu .active::after {
    content: "";
    width: 100%;
    -webkit-transform: none;
            transform: none;
  }
  .mobile-btn {
    display: block;
  }
  .mobile-nav-menu {
    -webkit-transition: 850ms;
    transition: 850ms;
    right: -100%;
    width: 250px;
    height: 100vh;
  }
  .mobile-nav-menu ul {
    display: none;
  }
}
