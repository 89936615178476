.about-container {
  padding: 10% 15% 0% 15%;
  min-height: 55vh;
  margin: auto;
  display: flex;
  .about-wrap {
    margin: auto;
    width: 90%;
  }
  .about-me-title {
    font-size: 80px;
    white-space: pre-wrap;
    color: #734A44;
  }
  .about-me-text {
    font-size: 18px;
    text-align: right;
    span {
      color: #f5b77a;
    }
  }
  .about-title-container {
    width: auto;
  }
  .about-btn {
    display: flex;
    justify-content: right;
    a {
      text-decoration: none;
    }
  }
}

@media only screen and (max-width: 1000px) {
  .about-container {
    padding: 10% 10% 0% 15%;
    .about-me-title {
      font-size: 65px;
      white-space: pre-wrap;
      color: #734A44;
    }
  }
}

@media only screen and (max-width: 765px) {
  .about-container {
    padding: 15% 5% 0% 5%;
    min-height: 35vh;
    .about-me-title {
      font-size: 45px;
      white-space: pre-wrap;
      color: #734A44;
    }
    .about-me-text {
      font-size: 13px;
      text-align: right;
    }
    .about-btn {
      display: flex;
      justify-content: center;
      a {
        text-decoration: none;
      }
    }
  }
}