.contact-wrapper {
  background: no-repeat url(/src/images/darkbrownwavy.png);
  background-size: 100% 100%;
  padding: 5% 0 5% 0;
}

.contact-title {
  margin: auto;
  font-size: 40px;
  text-align: center;
  color: white;
}

.contact-error {
  color: rgb(255, 255, 255);
  font-size: 13px;
  padding-left: 10px;
}
.form-wrap {
  width: fit-content;
  margin: auto;
  width: 40%;
  .form-btn-wrap {
    display: flex;
    justify-content: space-between;
    width: auto;
    margin: 0% 2% 0% 2%;
    .form-socials {
      width: 50%;
      a {
        color: black;
        margin: 0% 4% 0% 0%;
      }
      .fa-github {
        &:hover {
          color: rgb(255, 255, 255);
        }
      }
      .fa-linkedin {
        &:hover {
          color: rgb(255, 255, 255);
        }
      }
    }
  }
  div {
    margin: 1%;
    width: 98%;
    border-radius: 0;
  }
}

@media only screen and (max-width: 765px) {
  .form-wrap {
    width: 70%;
  }
  .contact-title {
    font-size: 35px;
  }
}
