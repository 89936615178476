.contact-wrapper {
  background: no-repeat url(/src/images/darkbrownwavy.png);
  background-size: 100% 100%;
  padding: 5% 0 5% 0;
}

.contact-title {
  margin: auto;
  font-size: 40px;
  text-align: center;
  color: white;
}

.contact-error {
  color: white;
  font-size: 13px;
  padding-left: 10px;
}

.form-wrap {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  width: 40%;
}

.form-wrap .form-btn-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: auto;
  margin: 0% 2% 0% 2%;
}

.form-wrap .form-btn-wrap .form-socials {
  width: 50%;
}

.form-wrap .form-btn-wrap .form-socials a {
  color: black;
  margin: 0% 4% 0% 0%;
}

.form-wrap .form-btn-wrap .form-socials .fa-github:hover {
  color: white;
}

.form-wrap .form-btn-wrap .form-socials .fa-linkedin:hover {
  color: white;
}

.form-wrap div {
  margin: 1%;
  width: 98%;
  border-radius: 0;
}

@media only screen and (max-width: 765px) {
  .form-wrap {
    width: 70%;
  }
  .contact-title {
    font-size: 35px;
  }
}
